<template>
    <div>
        <TheSnapshotMoveAndCopyFiles
            :selectedFiles="filesToMoveOrCopy"
            :operationType="copyOrMoveOperation"
            :folderTypeSelected="folderTypeSelected"
            @clearCopyAndMoveFiles="clearCopyAndMoveFiles()"
            @removeFileFromMoveCopyList="removeFileFromMoveCopyList($event.fid)" />
        <div v-if="snapshotFilesystemPrefix" class="d-flex justify-space-between align-space-between w-100">
            <div class="d-inline">
                <span class="secondary--text font-weight-bold text-h6">Files</span>
            </div>
        </div>

        <div v-if="!filesFetching && (currentFiles.length || pathArray.length > 1) && snapshotFilesystemPrefix" width="50%" fluid class="pt-0">
            <v-snackbar v-model="stageSuggestionSnackbar" :timeout="snackbarTimeout" color="info">
                <v-icon class="mr-1" dark>info</v-icon>
                Add uploaded files to stage to share with others?
                <v-btn color="white" outlined @click="addToStaging(lastUploadedFiles, 'uploaded')">Stage</v-btn>
                <v-btn color="white" text @click="discardUploaded">Discard</v-btn>
            </v-snackbar>
            <v-breadcrumbs large :items="pathArray" class="pt-3 pb-5">
                <template v-slot:item="props">
                    <a @click="$store.dispatch('snapshotStore/setTreeLevel', { snid: $route.params.snid, level: props.item.index + 1 })">
                        <span class="font-weight-bold secondary--text" v-if="props.item.index > 0">{{ props.item.path }}</span>
                        <v-icon v-else-if="pathArray.length === 1" color="secondary">folder</v-icon>
                        <v-icon v-else color="secondary">mdi-folder-open</v-icon>
                    </a>
                </template>
                <template v-slot:divider>
                    <v-icon>double_arrow</v-icon>
                </template>
            </v-breadcrumbs>
            <v-text-field autofocus outlined dense hide-details label="Filter files..." v-model="search"></v-text-field>
        </div>
        <v-skeleton-loader v-if="filesFetching" class="mx-auto" type="table"></v-skeleton-loader>
        <v-banner v-else-if="!snapshotFilesystemPrefix" single-line>
            <v-avatar slot="icon" color="white" size="60">
                <v-icon x-large icon="info" color="info">info</v-icon>
            </v-avatar>
            <div class="d-flex flex-column">
                <template v-if="isInstanceEditor && isDevelopment">
                    <span class="font-weight-bold secondary--text">Archived state</span>
                    <p>
                        Files are not available in the Current State of this instance. To access files, you may either
                        <a @click="goToSnapshots()">restore</a>
                        a snapshot to Current State or browse files of snapshots that are not in cold storage.
                    </p>
                </template>
                <template v-else-if="isInstanceEditor && !isDevelopment">
                    <span class="font-weight-bold secondary--text">Snapshot in cold storage</span>
                    <p>
                        This snapshot is in cold storage, its files cannot be accessed directly. To access its files, you must first
                        <a @click="goToSnapshots()">restore</a>
                        it to the Current State.
                    </p>
                </template>
                <template v-else>
                    <span class="font-weight-bold secondary--text">Snapshot in cold storage</span>
                    <p>
                        This snapshot is in cold storage and you don't have the privileges to restore it. You may try browsing other snapshots that are not in
                        cold storage, or reach out to support for retrieval.
                    </p>
                </template>
            </div>
        </v-banner>
        <v-banner v-else-if="pathArray.length === 1 && !currentFiles.length" single-line>
            <v-avatar slot="icon" color="white" size="60">
                <v-icon x-large icon="info" color="info">info</v-icon>
            </v-avatar>
            <div class="d-flex flex-column">
                <span class="font-weight-bold secondary--text">No files found</span>
                <span>
                    Currently you don't have
                    <span v-if="folderTypeSelected === folderTypesLabels.WORKSPACE_FILES">workspace</span>
                    <span v-else-if="folderTypeSelected === folderTypesLabels.PERSONAL_FILES">personal</span>
                    files.
                    <span v-if="isDevelopment">
                        Consider adding new files via the
                        <v-icon>upload</v-icon>
                        icon above
                    </span>
                    or you can also
                    <a href="https://docs.nuvolos.cloud/features/file-system-and-storage/synchronize-with-dropbox" target="_blank">
                        set up Dropbox integration
                    </a>
                    .
                </span>
            </div>
        </v-banner>
        <v-data-table
            :headers="headers"
            :items="getTableData(filesFetching, currentFiles)"
            :show-select="currentFiles.length > 0"
            v-model="selected"
            item-key="fid"
            :options="pagination"
            :search="search"
            hide-default-footer
            v-else
            class="shepherd-staging-step-1">
            <template v-slot:top="{ options, pagination, updateOptions }">
                <div class="d-flex align-center">
                    <v-switch v-model="showHidden" label="Hidden Files" class="pr-3 pl-3 mb-0"></v-switch>
                    <v-spacer></v-spacer>
                    <v-data-footer
                        style="border-top: none"
                        prev-icon="mdi-arrow-left-circle"
                        next-icon="mdi-arrow-right-circle"
                        :pagination="pagination"
                        :items-per-page-options="itemsPerPageOptions"
                        :options="options"
                        @update:options="updateOptions">
                        <template v-slot:page-text="pagetext">
                            <v-alert color="info" text dense class="py-1 px-2 my-0">
                                <span class="caption">
                                    Listing files {{ pagetext.pageStart }} - {{ pagetext.pageStop }} (out of {{ pagetext.itemsLength }})
                                </span>
                            </v-alert>
                        </template>
                    </v-data-footer>
                    <v-menu v-model="multipleFilesActionsMenu" v-if="currentFiles.length" :close-on-content-click="false" offset-y bottom>
                        <template v-slot:activator="{ on }">
                            <v-badge :color="selected.length ? 'primary' : 'transparent'" :content="selected.length" overlap>
                                <v-btn v-on="on" color="secondary" icon dark class="caption">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </v-badge>
                        </template>
                        <v-list dense nav>
                            <v-list-item v-if="mergedOptions.stage">
                                <v-list-item-title>
                                    <v-btn @click="addToStaging(currentFiles, 'selected')" :disabled="!currentFiles.length" color="secondary" block text>
                                        <div class="d-flex aling-center justify-start w-100">
                                            <v-icon class="mr-1" small>share</v-icon>
                                            stage all ({{ currentFiles.length }})
                                        </div>
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="mergedOptions.stage">
                                <v-list-item-title>
                                    <v-btn
                                        @click="addToStaging(selected, 'selected')"
                                        :disabled="!selected.length"
                                        color="secondary"
                                        block
                                        text
                                        class="justify-start">
                                        <v-icon class="mr-1" small>share</v-icon>
                                        stage selected
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider class="my-3" v-if="groupMenuFirstDivider()"></v-divider>
                            <v-list-item v-if="mergedOptions.download">
                                <v-list-item-title>
                                    <v-btn
                                        :disabled="multipleFilesDownloadLink === null || fetchingMultipleDownloadToken"
                                        :href="multipleFilesDownloadLink"
                                        color="secondary"
                                        text
                                        block>
                                        <div class="d-flex align-center justify-start w-100">
                                            <v-icon class="mr-1" small>mdi-download</v-icon>
                                            download
                                        </div>
                                    </v-btn>
                                </v-list-item-title>
                                <v-list-item-action v-if="selected.length > 10">
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on" color="grey lighten-1">mdi-information</v-icon>
                                        </template>
                                        <span>Downloading multiple files is possible only for a maximum of 10 files.</span>
                                    </v-tooltip>
                                </v-list-item-action>
                            </v-list-item>
                            <template v-if="isDevelopment">
                                <v-list-item v-if="mergedOptions.move">
                                    <v-list-item-title>
                                        <v-btn @click="addToMoveCopyList(selected, 'move', true)" :disabled="!selected.length" color="secondary" text block>
                                            <div class="d-flex align-center justify-start w-100">
                                                <v-icon class="mr-1" small>mdi-folder-move</v-icon>
                                                move
                                            </div>
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="mergedOptions.copy">
                                    <v-list-item-title>
                                        <v-btn @click="addToMoveCopyList(selected, 'copy', true)" :disabled="!selected.length" color="secondary" text block>
                                            <div class="d-flex align-center justify-start w-100">
                                                <v-icon class="mr-1" small>content_copy</v-icon>
                                                copy
                                            </div>
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider class="my-3" v-if="groupMenuSecondDivider()"></v-divider>
                                <v-list-item v-if="mergedOptions.delete">
                                    <v-list-item-title class="subtitle-2 secondary--text">
                                        <DeleteButton
                                            objectType="file"
                                            :buttonName="getDeleteLabel"
                                            protocol="POST"
                                            :request-body="{ fids: selectedFileIds, mode: 0 }"
                                            apiURL="/files/delete_async"
                                            :disabled="selected.length === 0"
                                            :isAsyncDeletion="true"
                                            :showDialog="true"
                                            :warningText="confirmQuestion(0)"
                                            @error="errorMessage($event.error)"
                                            @deleting="emptySelected(!$event.value)"
                                            fetchString="snapshotStore/fetchCurrentFiles"
                                            :objectName="selectedFileNames.toString()"></DeleteButton>
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                            <v-list-item v-if="isSpaceAdmin && mergedOptions.delete && currentSpaceType !== spaceTypes.RESEARCH_SPACE">
                                <v-list-item-title class="subtitle-2 secondary--text">
                                    <DeleteButton
                                        objectType="file"
                                        :buttonName="getDeleteForOthersLabel"
                                        protocol="POST"
                                        :request-body="{ fids: selectedFileIds, mode: 2 }"
                                        apiURL="/files/delete_async"
                                        :disabled="selected.length === 0"
                                        :isAsyncDeletion="true"
                                        :showDialog="true"
                                        :warningText="confirmQuestion(2)"
                                        @error="errorMessage($event.error)"
                                        @deleting="emptySelected(!$event.value)"
                                        fetchString="snapshotStore/fetchCurrentFiles"
                                        :objectName="selectedFileNames.toString()"></DeleteButton>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="
                                    isDevelopment && isSpaceAdmin && isMasterInstance && mergedOptions.delete && currentSpaceType !== spaceTypes.RESEARCH_SPACE
                                ">
                                <v-list-item-title class="subtitle-2 secondary--text">
                                    <DeleteButton
                                        objectType="file"
                                        buttonName="Delete for all"
                                        protocol="POST"
                                        :request-body="{ fids: selectedFileIds, mode: 1 }"
                                        apiURL="/files/delete_async"
                                        :disabled="selected.length === 0"
                                        :isAsyncDeletion="true"
                                        :showDialog="true"
                                        :warningText="confirmQuestion(1)"
                                        @error="errorMessage($event.error)"
                                        @deleting="emptySelected(!$event.value)"
                                        fetchString="snapshotStore/fetchCurrentFiles"
                                        :objectName="selectedFileNames.toString()"></DeleteButton>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.fid" :search="search" @mouseover="selectItem(item)">
                        <td>
                            <v-checkbox multiple :value="item" v-model="selected" class="pa-0 ma-0" hide-details flat />
                        </td>
                        <td>
                            <div v-if="item.type === nuvolosObjectTypes.FOLDER" class="d-flex align-center">
                                <v-icon class="mr-3">folder</v-icon>
                                <a class="secondary--text subtitle-2" @click="openDirectory(item.short_id)">{{ item.short_id }}</a>
                            </div>
                            <div v-else class="d-flex align-center">
                                <v-menu offset-y right min-width="0">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" text class="text-none" @click="fetchFileDownloadToken(item)">
                                            <v-icon left color="primary">{{ fileIcon(item.type, item.short_id) }}</v-icon>
                                            {{ item.short_id }}
                                            <v-icon right class="showOnHover">mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list dense nav>
                                        <v-list-item v-if="item.short_id && item.short_id.toLowerCase().endsWith('.pdf') && mergedOptions.view">
                                            <v-list-item-title>
                                                <pdf-viewer :pdfUrl="previewBlobUrl" :longId="previewLongId">
                                                    <v-btn @click="previewFile(item)" color="secondary" text :loading="downloadingFileForPreview" block>
                                                        <div class="d-flex align-center justify-start w-100">
                                                            <v-icon left>mdi-eye</v-icon>
                                                            view
                                                        </div>
                                                    </v-btn>
                                                </pdf-viewer>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="item.short_id && item.short_id.toLowerCase().endsWith('.ipynb')">
                                            <v-list-item-title>
                                                <notebook-viewer :notebookContent="notebookContent" :longId="nbFile">
                                                    <v-btn
                                                        @click="previewNotebook(item)"
                                                        color="secondary"
                                                        class="justify-start"
                                                        text
                                                        :loading="downloadingFileForPreview"
                                                        block>
                                                        <v-icon left>mdi-eye</v-icon>
                                                        view
                                                    </v-btn>
                                                </notebook-viewer>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="mergedOptions.download">
                                            <v-list-item-title>
                                                <v-btn
                                                    :disabled="currentFileDownloadLink === null || fetchingFileToken"
                                                    :href="currentFileDownloadLink"
                                                    color="secondary"
                                                    class="justify-start"
                                                    text
                                                    block>
                                                    <v-icon left>mdi-download</v-icon>
                                                    download
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="
                                                isDevelopment && isEditableFile(item) && mergedOptions.view && !item.short_id.toLowerCase().endsWith('.ipynb')
                                            ">
                                            <v-list-item-title>
                                                <the-snapshot-file-editor
                                                    :endpoint="endpoint"
                                                    :fileData="item"
                                                    :language="fileLanguage(item)"
                                                    :isMarkdownFile="fileLanguage(item) === editorLanguages.md"
                                                    :isBlock="true"
                                                    :loading="isEditableFileFetching"
                                                    :editable="mergedOptions.edit"></the-snapshot-file-editor>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </td>
                        <td>
                            <FileStatus :fileData="item" :options="fileStatusOptions"></FileStatus>
                        </td>
                        <td class="text-right">
                            <span class="grey--text">{{ humanFileSize(item.size) }}</span>
                        </td>
                        <td class="text-right">
                            <template v-if="item === selectedItem">
                                <v-btn
                                    @click="addToStaging([item], 'selected')"
                                    color="secondary"
                                    class="mr-2"
                                    small
                                    outlined
                                    text
                                    v-if="$vuetify.breakpoint.mdAndUp && mergedOptions.stage">
                                    <v-icon class="mr-1" small>share</v-icon>
                                    stage
                                </v-btn>
                                <v-menu :close-on-content-click="false" left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" text color="secondary" @click="fetchFileDownloadToken(item)" small class="mr-3">
                                            <v-icon>more_horiz</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-text class="ma-0 pt-1 pb-1 pl-0 pr-0">
                                            <v-list nav dense>
                                                <v-list-item v-if="mergedOptions.download">
                                                    <v-list-item-title>
                                                        <v-btn
                                                            :disabled="currentFileDownloadLink === null || fetchingFileToken"
                                                            :href="currentFileDownloadLink"
                                                            :download="item.type === nuvolosObjectTypes.FOLDER ? `${item.short_id}.zip` : item.short_id"
                                                            color="secondary"
                                                            text
                                                            block
                                                            class="justify-start">
                                                            <v-icon class="mr-1" small>mdi-download</v-icon>
                                                            download
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <template v-if="isDevelopment">
                                                    <v-list-item v-if="item.short_id && item.short_id.toLowerCase().endsWith('.pdf') && mergedOptions.view">
                                                        <v-list-item-title>
                                                            <pdf-viewer :pdfUrl="previewBlobUrl" :longId="previewLongId">
                                                                <v-btn
                                                                    @click="previewFile(item)"
                                                                    color="secondary"
                                                                    text
                                                                    :loading="downloadingFileForPreview"
                                                                    block
                                                                    class="justify-start">
                                                                    <v-icon class="mr-1" small>mdi-eye</v-icon>
                                                                    view
                                                                </v-btn>
                                                            </pdf-viewer>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="item.short_id && item.short_id.toLowerCase().endsWith('.ipynb')">
                                                        <v-list-item-title>
                                                            <v-btn
                                                                @click="previewNotebook(item)"
                                                                color="secondary"
                                                                class="justify-start"
                                                                text
                                                                :loading="downloadingFileForPreview"
                                                                block>
                                                                <v-icon left>mdi-eye</v-icon>
                                                                view
                                                            </v-btn>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-divider class="my-3" v-if="itemMenuFirstDivider()"></v-divider>
                                                    <v-list-item v-if="mergedOptions.rename">
                                                        <v-list-item-title class="subtitle-2 secondary--text">
                                                            <RenameButton
                                                                :selected="[item]"
                                                                objectType="file"
                                                                @finished="emptySelected($event.value)"
                                                                :dispatchFunction="
                                                                    () => {
                                                                        $store.dispatch('snapshotStore/fetchCurrentFiles', {
                                                                            id: $route.params.snid,
                                                                            route: $route,
                                                                            setFetchingStatus: true
                                                                        })
                                                                    }
                                                                "></RenameButton>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="$vuetify.breakpoint.smAndDown && mergedOptions.stage">
                                                        <v-list-item-title class="subtitle-2 secondary--text">
                                                            <v-btn @click="addToStaging([item], 'selected')" color="secondary" text>
                                                                <v-icon class="mr-1" small>mdi-playlist-plus</v-icon>
                                                                stage
                                                            </v-btn>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="mergedOptions.move">
                                                        <v-list-item-title class="subtitle-2 secondary--text">
                                                            <v-btn @click="addToMoveCopyList([item], 'move')" color="secondary" text block>
                                                                <div class="d-flex align-center justify-start w-100">
                                                                    <v-icon class="mr-1" small>mdi-folder-move</v-icon>
                                                                    move
                                                                </div>
                                                            </v-btn>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <!-- <v-list-item v-if="isDevelopment && isEditableFile(item) && mergedOptions.edit"> -->
                                                    <v-list-item v-if="isDevelopment && isEditableFile(item) && mergedOptions.view">
                                                        <v-list-item-title>
                                                            <the-snapshot-file-editor
                                                                :endpoint="endpoint"
                                                                :fileData="item"
                                                                :language="fileLanguage(item)"
                                                                :isMarkdownFile="fileLanguage(item) === editorLanguages.md"
                                                                :isBlock="true"
                                                                :loading="isEditableFileFetching"
                                                                :editable="mergedOptions.edit"></the-snapshot-file-editor>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="mergedOptions.copy">
                                                        <v-list-item-title class="subtitle-2 secondary--text">
                                                            <v-btn @click="addToMoveCopyList([item], 'copy')" color="secondary" text block>
                                                                <div class="d-flex align-center justify-start w-100">
                                                                    <v-icon class="mr-1" small>content_copy</v-icon>
                                                                    copy
                                                                </div>
                                                            </v-btn>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="item.type !== nuvolosObjectTypes.FOLDER && mergedOptions.duplicate">
                                                        <v-list-item-title class="subtitle-2 secondary--text">
                                                            <v-btn block @click="duplicateFile(item)" color="secondary" text>
                                                                <div class="d-flex align-center justify-start w-100">
                                                                    <v-icon class="mr-1" small>mdi-content-duplicate</v-icon>
                                                                    duplicate
                                                                </div>
                                                            </v-btn>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-divider class="my-3" v-if="groupMenuSecondDivider()"></v-divider>
                                                    <v-list-item v-if="mergedOptions.delete">
                                                        <v-list-item-title class="subtitle-2 secondary--text">
                                                            <DeleteButton
                                                                objectType="file"
                                                                :buttonName="getDeleteLabel"
                                                                protocol="POST"
                                                                :request-body="{ fids: [item.fid], mode: 0 }"
                                                                apiURL="/files/delete_async"
                                                                :isAsyncDeletion="true"
                                                                :showDialog="true"
                                                                :warningText="`Are you sure you want to delete - ${item.short_id.toUpperCase()} - permanently?`"
                                                                @error="errorMessage($event.error)"
                                                                @deleting="emptySelected(!$event.value)"
                                                                fetchString="snapshotStore/fetchCurrentFiles"
                                                                :objectName="item.short_id"></DeleteButton>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                                <v-list-item v-if="isSpaceAdmin && mergedOptions.delete">
                                                    <v-list-item-title class="subtitle-2 secondary--text">
                                                        <DeleteButton
                                                            objectType="file"
                                                            :buttonName="getDeleteForOthersLabel"
                                                            protocol="POST"
                                                            :request-body="{ fids: [item.fid], mode: 2 }"
                                                            apiURL="/files/delete_async"
                                                            :isAsyncDeletion="true"
                                                            :showDialog="true"
                                                            :warningText="`Are you sure you want to delete all distributed versions of - ${item.short_id.toUpperCase()} - from the other instances permanently? The file would still be available for you.`"
                                                            @error="errorMessage($event.error)"
                                                            @deleting="emptySelected(!$event.value)"
                                                            fetchString="snapshotStore/fetchCurrentFiles"
                                                            :objectName="item.short_id"></DeleteButton>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-if="isDevelopment && isSpaceAdmin && isMasterInstance && mergedOptions.delete">
                                                    <v-list-item-title class="subtitle-2 secondary--text">
                                                        <DeleteButton
                                                            objectType="file"
                                                            buttonName="Delete for all"
                                                            protocol="POST"
                                                            :request-body="{ fids: [item.fid], mode: 1 }"
                                                            apiURL="/files/delete_async"
                                                            :isAsyncDeletion="true"
                                                            :showDialog="true"
                                                            :warningText="`Are you sure you want to delete your and all distributed versions of - ${item.short_id.toUpperCase()} - permanently?`"
                                                            @error="errorMessage($event.error)"
                                                            @deleting="emptySelected(!$event.value)"
                                                            fetchString="snapshotStore/fetchCurrentFiles"
                                                            :objectName="item.short_id"></DeleteButton>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </td>
                    </tr>
                    <tr v-if="!items.length">
                        <td colspan="5">
                            <div class="mt-3 mb-3">
                                <v-alert dense type="info" text v-if="filesFetching">Loading the list of files, please wait...</v-alert>
                                <v-alert dense type="warning" text v-else-if="pathArray.length > 1">No files.</v-alert>
                                <v-alert dense type="warning" text v-else-if="!filesFetching">
                                    Currently you don't have
                                    <span v-if="folderTypeSelected === folderTypesLabels.WORKSPACE_FILES">workspace</span>
                                    <span v-else-if="folderTypeSelected === folderTypesLabels.PERSONAL_FILES">personal</span>
                                    files. Use the
                                    <span class="font-weight-bold">Hidden Files</span>
                                    switch above to display any hidden files.
                                </v-alert>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DeleteButton from '@/components/DeleteButton'
import { mapGetters, mapState } from 'vuex'
import { humanFileSize } from '@/utils'
import 'github-markdown-css/github-markdown.css'
import 'highlight.js/styles/school-book.css'
import { enumsData } from '@/mixins/enums'
import files from '@/mixins/files'
import Shepherd from 'shepherd.js'

// const UppyUpload = () => import('@/components/UppyUpload')
const RenameButton = () => import('../../snapshot/components/TheSnapshotRenameButton')
const FileStatus = () => import('../../snapshot/components/TheSnapshotFileStatus')
const PdfViewer = () => import('../../snapshot/components/PdfViewer.vue')
// const CreateFolder = () => import('../../snapshot/components/TheSnapshotCreateFolder')
const TheSnapshotMoveAndCopyFiles = () => import('../../snapshot/components/TheSnapshotMoveAndCopyFiles')
const TheSnapshotFileEditor = () => import('../../snapshot/components/TheSnapshotFileEditor.vue')
const NotebookViewer = () => import('@/modules/snapshot/components/NotebookViewer.vue')

function initialState() {
    return {
        search: '',
        headers: [
            { text: 'File', align: 'left', value: 'short_id', class: 'pl-8' },
            {
                text: 'Last modified',
                value: 'last_modified_timestamp',
                width: '235px'
            },
            { text: 'Size', value: 'size', align: 'end' },
            { text: 'Actions', align: 'right', value: 'actions', width: '200px' }
        ],
        selected: [],
        filesToMoveOrCopy: [],
        folderTypeSelected: 'files',
        numFilesToDelete: 0,
        itemsPerPageOptions: [25, 50, 100, -1],
        currentPathString: '',
        downloading: [],
        pagination: {
            page: 0,
            itemsPerPage: 25,
            pageStart: 1,
            pageStop: 25,
            itemsLength: 25
        },
        currentFilesVisible: [],
        multipleFilesActionsMenu: false,
        closeDelay: 100,
        showHidden: false,
        fetchingMultipleDownloadToken: false,
        show: false,
        readmeMDLastModified: '',
        stageSuggestionSnackbar: false,
        snackbarTimeout: 10000,
        denseTable: false,
        panel: 1,
        error: false,
        errorContent: '',
        selectedItem: false,
        expandedTable: [],
        filesBatchData: [],
        previewBlobUrl: '',
        notebookContent: null,
        nbFile: '',
        previewLongId: '',
        fetchingFileToken: false,
        currentFileDownloadLink: null,
        multipleFilesDownloadLink: null,
        downloadingFileForPreview: false,
        copyOrMoveOperation: null,
        fileEditContent: '',
        editorLanguages: {
            cpp: 'cpp',
            css: 'css',
            html: 'html',
            ini: 'ini',
            java: 'java',
            js: 'javascript',
            json: 'json',
            md: 'markdown',
            php: 'php',
            py: 'python',
            r: 'r',
            scala: 'scala',
            sh: 'shell',
            sql: 'sql',
            yaml: 'yaml'
        },
        stagingTour: null,
        defaultOptions: {
            stage: true,
            rename: true,
            move: true,
            edit: true,
            copy: true,
            duplicate: true,
            delete: true,
            view: true,
            download: true
        },
        mergedOptions: null,
        fileStatusOptions: {
            icon: false
        },
        mimeType: '',
        isEditableFileFetching: false
    }
}

export default {
    name: 'snapshot-files',
    mixins: [enumsData, files],
    data: () => {
        return initialState()
    },
    props: {
        apiId: Number,
        options: Object
    },
    created() {
        this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.apiId, route: this.$route, setFetchingStatus: true })
        this.mergedOptions = Object.assign(this.defaultOptions, this.options)
    },
    computed: {
        endpoint() {
            const snid = this.$route.params.snid
            const type =
                this.folderTypeSelected === this.folderTypesLabels.PERSONAL_FILES
                    ? this.$store.state.snapshotStore.fileAreaType + '/' + this.$store.state.userInfo.uid
                    : this.$store.state.snapshotStore.fileAreaType
            return `/snapshots/${snid}/fs/${type}` + this.$store.state.snapshotStore.pathArray.join('/')
        },
        quotaPercentageUsage() {
            if (this.snapshotQuota) {
                return this.snapshotQuota.bytes_pcent || 0
            }
            return null
        },
        snapshotFilesystemPrefix() {
            return this.$store.getters['snapshotStore/snapshotFilesystemPrefixById'](this.$route.params.snid)
        },
        pathArray() {
            return this.$store.state.snapshotStore.pathArray.map((v, i) => {
                return { path: v, index: i }
            })
        },
        currentFiles() {
            return this.$store.state.snapshotStore.currentFiles.map(f => {
                f.isFolder = f.type === this.nuvolosObjectTypes.FOLDER ? 1 : 0
                return f
            })
        },
        ...mapState('snapshotStore', [
            'showUploadSnackbar',
            'stagingObjects',
            'filesFetching',
            'lastUploadedFiles',
            'currentTables',
            'restoringSnapshot',
            'snapshotQuota'
        ]),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapGetters('spaceStore', ['currentSpaceType', 'isSpaceAdmin']),
        ...mapState(['userInfo']),
        ...mapState('tourStore', ['stageTourStarted']),
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isMasterInstance', 'isDistributedInstance']),
        selectedFileIds() {
            return this.selected.map(file => file.fid)
        },
        selectedFileNames() {
            return this.selected.map(file => file.short_id)
        },
        getDeleteForOthersLabel() {
            return this.isDistributedInstance && this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE ? 'Delete for students' : 'Delete for others'
        },
        getDeleteLabel() {
            return this.isSpaceAdmin && this.isMasterInstance && !this.isHomeFilesArea && this.currentSpaceType !== this.spaceTypes.RESEARCH_SPACE
                ? 'Delete for me'
                : 'Delete'
        }
    },
    methods: {
        confirmQuestion(type) {
            const ul = document.createElement('ul')
            this.selected.forEach(file => {
                const li = document.createElement('li')
                li.appendChild(document.createTextNode(file.short_id))
                ul.appendChild(li)
            })
            const tmp = document.createElement('div')
            tmp.appendChild(ul)
            switch (type) {
                case 0:
                    return `Are you sure you want to delete: ${tmp.innerHTML} permanently?`
                case 1:
                    return `Are you sure you want to delete your and all distributed versions of: ${tmp.innerHTML} permanently?`
                case 2:
                    return `Are you sure you want to delete all distributed versions of: ${tmp.innerHTML} from the other instances permanently? The files would still be available for you.`
            }
            return `Are you sure you want to delete: ${tmp.innerHTML} permanently?`
        },
        restoreSnapshotToCurrentState: function () {
            this.$store.dispatch('snapshotStore/restoreSnapshotToCurrentState', { snid: this.$route.params.snid, createBackup: true })
        },
        resetInitialState: function () {
            Object.assign(this.$data, initialState())
        },
        humanFileSize: function (bytes, si) {
            return humanFileSize(bytes, si)
        },
        goToSnapshots() {
            this.$router.push({
                name: 'instance-snapshots',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid
                }
            })
        },
        async fetchIsEditableFile(file) {
            console.log('file:', file)
            this.isEditableFileFetching = true
            this.mimeType = ''
            try {
                if (!file.isFolder) {
                    const { data } = await this.$axios.get(`/files/${file.fid}/mime_type`)
                    this.mimeType = data.mime_type
                }
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Failed to fetch if file is editable or not.`,
                    snackBarTimeout: 10000,
                    snackBarIcon: 'error'
                })
            }
            this.isEditableFileFetching = false
        },
        fileLanguage(fileData) {
            const fileExtension = fileData.short_id.toLowerCase().split('.').pop()
            const availableLanguages = Object.keys(this.editorLanguages)
            if (availableLanguages.includes(fileExtension)) {
                return this.editorLanguages[fileExtension]
            } else {
                return ''
            }
        },
        sortFilesList(files) {
            const sortedByDate = files.sort(function (a, b) {
                return new Date(b.last_modified_timestamp) - new Date(a.last_modified_timestamp)
            })
            const foldersList = sortedByDate.filter(file => file.isFolder === 1)
            const filesList = sortedByDate.filter(file => file.isFolder === 0)
            return foldersList.concat(filesList)
        },
        getTableData: function (filesFetching, currentFiles) {
            if (filesFetching) {
                return []
            } else if (this.showHidden) {
                const filteredData = currentFiles.filter(f => {
                    return f.short_id !== '.git'
                })
                return this.sortFilesList(filteredData)
            } else {
                const filteredData = currentFiles.filter(f => {
                    return !f.short_id.startsWith('.')
                })
                return this.sortFilesList(filteredData)
            }
        },
        changeFileType(type) {
            if (type === this.folderTypesLabels.WORKSPACE_FILES) {
                this.folderTypeSelected = this.folderTypesLabels.WORKSPACE_FILES
                this.$router.push({
                    name: 'snapshot-files',
                    params: {
                        oid: this.$route.params.oid,
                        sid: this.$route.params.sid,
                        iid: this.$route.params.iid,
                        snid: this.$route.params.snid,
                        fileArea: this.folderTypesLabels.WORKSPACE_FILES
                    }
                })
            } else if (type === this.folderTypesLabels.PERSONAL_FILES) {
                this.folderTypeSelected = this.folderTypesLabels.PERSONAL_FILES
                this.$router.push({
                    name: 'snapshot-files',
                    params: {
                        oid: this.$route.params.oid,
                        sid: this.$route.params.sid,
                        iid: this.$route.params.iid,
                        snid: this.$route.params.snid,
                        fileArea: this.folderTypesLabels.PERSONAL_FILES
                    }
                })
            }
        },
        selectItem(item) {
            this.selectedItem = item
        },
        openDirectory: function (shortId) {
            this.selected = []
            this.$store.dispatch('snapshotStore/openDirectory', {
                nextDirectory: shortId
            })
        },
        clearCopyAndMoveFiles: function () {
            if (this.filesToMoveOrCopy.length) {
                this.filesToMoveOrCopy = []
            }
        },
        removeFileFromMoveCopyList: function (fid) {
            if (fid) {
                this.filesToMoveOrCopy = this.filesToMoveOrCopy.filter(file => file.fid !== fid)
            }
        },
        addToMoveCopyList: function (filesArray, operation, multipleSelection = false) {
            if (!this.copyOrMoveOperation || operation !== this.copyOrMoveOperation) {
                this.copyOrMoveOperation = operation
                this.filesToMoveOrCopy = []
            }
            const selectedFilesIds = this.filesToMoveOrCopy.map(file => file.fid)
            if (filesArray.length) {
                filesArray.forEach(file => {
                    if (!selectedFilesIds.includes(file.fid)) {
                        this.filesToMoveOrCopy.push(file)
                    }
                })
                if (multipleSelection) {
                    this.selected = []
                }
            }
        },
        duplicateFile: function (fileData) {
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            const successMessage = `${fileData.short_id.toUpperCase()} successfully submitted for duplication, you can track its progress <a href="${userTasksRoute}">here</a>`
            this.$axios
                .post(`/files/${fileData.fid}/duplicate_async`)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: successMessage,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                    this.$store.dispatch('userStore/fetchUserTasks')
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to duplicate ${fileData.short_id}`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
        },
        addToStaging: function (data, dataSource) {
            if (data.length) {
                const stagingObjectsBefore = this.stagingObjects.files.length
                const filesWithPaths = []
                data.forEach(file => {
                    const fileObject = file
                    if (file.local_path !== '') {
                        fileObject.filePath = file.local_path + '/' + file.short_id
                    } else {
                        fileObject.filePath = file.short_id
                    }
                    if (file.type === this.nuvolosObjectTypes.FOLDER) {
                        fileObject.filePath = fileObject.filePath + '/'
                    }
                    fileObject.fileType =
                        this.folderTypeSelected === this.folderTypesLabels.WORKSPACE_FILES
                            ? this.userWorkAreas.WORKSPACE
                            : this.folderTypeSelected === this.folderTypesLabels.PERSONAL_FILES
                            ? this.userWorkAreas.PERSONAL
                            : 'unknown'
                    filesWithPaths.push(fileObject)
                })
                this.$store.dispatch('snapshotStore/updateStagingObjects', {
                    itemType: this.nuvolosObjectTypes.FILE,
                    newItems: filesWithPaths,
                    updateMode: 'add'
                })
                if (dataSource === 'selected') {
                    this.selected = []
                } else if (dataSource === 'uploaded') {
                    this.$store.dispatch('snapshotStore/setShowUploadSnackbar', false)
                    this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                        newData: [],
                        updateMode: 'clear'
                    })
                }
                const stagingObjectsAfter = this.stagingObjects.files.length
                if (stagingObjectsAfter !== stagingObjectsBefore) {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Files added to stage.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                } else {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Selected files already staged.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'info'
                    })
                }
            }
            this.panel = 0
            this.stagingTour && this.stagingTour.next()
        },
        discardUploaded: function () {
            this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                newData: [],
                updateMode: 'clear'
            })
            this.$store.dispatch('snapshotStore/setShowUploadSnackbar', false)
            this.stageSuggestionSnackbar = false
        },
        endsWithAny: function (suffixes, string) {
            return suffixes.some(suffix => {
                return string.endsWith(suffix)
            })
        },
        fileIcon: function (fileType, fileName) {
            if (fileName) {
                if (fileName.endsWith('.json')) {
                    return 'mdi-code-json'
                } else if (fileName.endsWith('.pdf')) {
                    return 'mdi-adobe-acrobat'
                } else if (this.endsWithAny(['.py', '.R', '.ipynb'], fileName)) {
                    return 'mdi-file-code-outline'
                } else if (this.endsWithAny(['.svg', '.png', '.jpeg', 'jpg', 'eps'], fileName)) {
                    return 'mdi-file-image-outline'
                } else if (this.endsWithAny(['.docx'], fileName)) {
                    return 'mdi-file-word-outline'
                } else {
                    return 'mdi-file-outline'
                }
            } else if (fileType) {
                if (fileType.includes('image')) {
                    return 'mdi-file-image'
                } else if (fileType.includes('application/pdf')) {
                    return 'mdi-adobe-acrobat'
                } else if (fileType.includes('officedocument.wordprocessingml.document')) {
                    return 'mdi-file-word-outline'
                } else if (fileType.includes('application/json')) {
                    return 'mdi-json'
                } else {
                    return 'mdi-file-outline'
                }
            } else {
                return 'mdi-file-outline'
            }
        },
        emptySelected(confirmed) {
            if (confirmed) {
                this.selected = []
            }
        },
        clearMoveList: function () {
            this.filesToMoveOrCopy = []
        },
        fetchFileDownloadToken(fileData) {
            this.fetchIsEditableFile(fileData)
            this.fetchingFileToken = true
            this.currentFileDownloadLink = null
            this.downloadLink = this.$axios
                .get(`/files/${fileData.fid}/download_link`)
                .then(response => {
                    this.currentFileDownloadLink = `${this.$axios.defaults.baseURL}/downloads/${response.data}`
                })
                .catch(error => {
                    console.log(error)
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Download of ${fileData.short_id} failed!`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.fetchingFileToken = false
                })
        },
        fetchMultipleFilesDownloadLink() {
            this.fetchingMultipleDownloadToken = true
            const postBody = { fids: this.selected.map(file => file.fid) }
            this.downloadLink = this.$axios
                .post('/files/download_link', postBody)
                .then(response => {
                    this.multipleFilesDownloadLink = `${this.$axios.defaults.baseURL}/downloads/${response.data}`
                })
                .finally(() => {
                    this.fetchingMultipleDownloadToken = false
                })
        },
        previewFile(fileData) {
            this.downloadingFileForPreview = true
            this.$axios.get(`/files/${fileData.fid}/download_link`).then(response => {
                this.$axios
                    .get(`/downloads/${response.data}`, { timeout: 300000, responseType: 'blob' })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                        this.previewBlobUrl = url
                        this.previewLongId = fileData.short_id
                    })
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Download of ${fileData.short_id} failed!`,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.downloadingFileForPreview = false
                    })
            })
        },
        setFolderTypeAndFetch(folderType, fileLevel) {
            this.$store.dispatch('snapshotStore/setFolderType', folderType)
            this.$store.dispatch('snapshotStore/setTreeLevel', {
                snid: this.$route.params.snid,
                level: fileLevel
            })
        },
        errorMessage: function (error) {
            if (error) {
                this.error = true
                this.errorContent = 'An error has occurred'
            }
        },
        itemMenuFirstDivider() {
            return this.options.download && Object.values(this.options).reduce((a, item) => a + item, 0) > 1
        },
        groupMenuFirstDivider() {
            return this.options.stage && Object.values(this.options).reduce((a, item) => a + item, 0) > 1
        },
        groupMenuSecondDivider() {
            return this.options.delete && Object.values(this.options).reduce((a, item) => a + item, 0) > 1
        },
        previewNotebook(fileData) {
            this.downloadingFileForPreview = true
            this.$axios.get(`/files/${fileData.fid}/download_link`).then(response => {
                this.$axios
                    .get(`/downloads/${response.data}`, { timeout: 300000 })
                    .then(({ data }) => {
                        this.notebookContent = data
                        this.nbFile = fileData.short_id
                    })
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Download of ${fileData.short_id} failed!`,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.downloadingFileForPreview = false
                    })
            })
        }
    },
    mounted() {
        this.folderTypeSelected = this.$store.state.snapshotStore.fileAreaType
    },
    watch: {
        apiId(newId) {
            this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: newId, route: this.$route, setFetchingStatus: true })
        },
        multipleFilesActionsMenu: function (newVal) {
            if (newVal && this.selected.length && this.selected.length <= 10) {
                this.fetchMultipleFilesDownloadLink()
            } else {
                this.multipleFilesDownloadLink = null
            }
        },
        folderTypeSelected(to, from) {
            const level = this.$route.params.localPath === undefined ? 1 : this.$route.params.localPath.split('/').length
            this.setFolderTypeAndFetch(to, level)
        },
        showUploadSnackbar: function (nextValue, prevValue) {
            this.stageSuggestionSnackbar = nextValue
        },
        stageTourStarted(val) {
            if (val) {
                this.stagingTour = new Shepherd.Tour({
                    useModalOverlay: true,
                    defaultStepOptions: {
                        scrollTo: { behavior: 'smooth' }
                    }
                })
                this.stagingTour.addStep({
                    title: 'Staging files',
                    text: `Click on the Stage button to stage a given file/folder.`,
                    attachTo: {
                        element: '.shepherd-staging-step-1',
                        on: 'top'
                    },
                    id: 'staging-files-1',
                    buttons: [
                        {
                            text: 'Ok',
                            action: this.stagingTour.complete
                        }
                    ]
                })
                this.stagingTour.addStep({
                    title: 'Staging files',
                    text: `Once you have staged all the files you would like to distribute (you can add multiple files / folders to the stage), \
                    you can access the stage here to continue with the distribution process.`,
                    attachTo: {
                        element: '.shepherd-staging-step-3',
                        on: 'right'
                    },
                    id: 'staging-files-3',
                    buttons: [
                        {
                            text: 'Got it!',
                            action: this.stagingTour.complete
                        }
                    ]
                })
                this.stagingTour.start()
                Shepherd.on('complete', () => {
                    this.stagingTour = null
                    this.$store.commit('tourStore/stageTourStarted', false)
                })
            }
        },
        filesFetching(newVal, oldVal) {
            // started fetching
            if (this.stageTourStarted && this.stagingTour && newVal && !oldVal) {
                this.$nextTick(() => {
                    this.stagingTour.cancel()
                })
            }
            // finished fetching
            if (this.stageTourStarted && this.stagingTour && !newVal && oldVal) {
                this.$nextTick(() => {
                    this.stagingTour.start()
                })
            }
        },
        currentFiles: {
            handler(to, from) {
                this.selected = to.filter(i => this.selected.filter(a => a.fid === i.fid).length)
                const readmeMD = to.filter(f => {
                    return f.short_id && f.short_id.toLowerCase() === 'readme.md'
                })
                if (readmeMD.length) {
                    if (readmeMD[0].last_modified !== this.readmeMDLastModified) {
                        this.readmeMDLastModified = readmeMD[0].last_modified_timestamp
                    }
                } else {
                    // this.readme_md_content = ''
                    this.readmeMDLastModified = ''
                }
            },
            immediate: true
        },
        $route: {
            handler(to, from) {
                if (to && from && to.params.snid !== from.params.snid) {
                    this.resetInitialState()
                }
                if (to.name !== 'snapshot-files') {
                    this.selected = []
                    this.search = ''
                    this.$store.dispatch('snapshotStore/setShowUploadSnackbar', false)
                    this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                        newData: [],
                        updateMode: 'clear'
                    })
                    this.clearMoveList()
                }
            },
            immediate: true
        }
    },
    components: {
        // UppyUpload,
        RenameButton,
        DeleteButton,
        FileStatus,
        PdfViewer,
        // CreateFolder,
        TheSnapshotMoveAndCopyFiles,
        TheSnapshotFileEditor,
        NotebookViewer
    }
}
</script>

<style lang="scss" scoped>
.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 980px;
    padding: 45px;
}

@media (max-width: 767px) {
    .markdown-body {
        padding: 15px;
    }
}

::v-deep .v-data-footer__pagination {
    margin-left: 0px;
    margin-right: 3px;
}
</style>
